// Should you need to add your custom variable or you need to override our theme variables from _variables.SCSS, then we highly recommend that you add the code into this file so that whenever theme update is available and you update the theme, You will not lose your hard work :)

// Below given example is for how to overrides core variables. Uncomment the code, after compilation, You will see the color scheme changes on frontend.

// User Variables
// Uncomment below code and change primary color to your brand color

/********************************************/
/*          TOMGAS                       		*/
/********************************************/

/*
TASK LIST:
✅ Menu - mouseover colour
✅ Menu - dropdown
✅ Menu - scrolldown size
✅ Menu - mobile
✅ Hero - why so dull?
✅ Intro - spacing
Fonts?
✅ Section - Titles
✅ Section - font-weight text, spacing
✅ Contact - Title
✅ Contact - font-weight text, spacing
✅ Contact - form width, caps
✅ Contact - button size
✅ Footer - font size & weights, spacing
✅ Footer - social media layout & sizes

Fonts:
https://fonts.google.com/specimen/Raleway
https://fonts.google.com/specimen/Outfit

Nav
Raleway 800 (note wide letter spacing)

Feature
Raleway 800 (Name)
Outfit 500 (Tagline)
Outfit 700 (Sub Heading)
Outfit 500 (Body)

Icons
Raleway 800

Headings
Raleway 700

Body
Outfit 400

Portfolio Scroller
Raleway 900

Testimonials
Outfit 400

Footer
Raleway 800
Outfit 400

*/

/********************************************************/
/* TGS - Font family settings				             			*/
/********************************************************/

/*
Header: 
Menu: 
Hero: 

Intro Title: 
Intro Text:  

Cards Title: 
Cards Text: 

Profile Title: 
Profile Text: 

Gallery: 
Gallery name: 

Testimonial: 
Testimonial name: 

Servicing Footer: 

Footer Title: 
Footer Link: 

White: #FFF
Green: 
Yellow: 
Black: #000

*/

// Main Logo Font
// Body font
// font-family: 'Raleway', sans-serif;
// font-family: 'Montserrat', sans-serif;
// font-family: 'Noto Sans JP', sans-serif;

// $font-family-base: "Noto Sans JP", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//$font-family-base: 'Nunito',-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */

// $font-family-base: "Rubik", sans-serif;
// $font-family-heading: "Spartan", sans-serif;
// $font-family-alt: "Reenie Beanie", cursive;

$font-raleway: "Raleway", "Helvetica Neue", sans-serif;
$font-outfit: "Outfit", "Helvetica Neue", sans-serif;

$font-family-base: $font-outfit;
$font-family-heading: $font-raleway;
$font-family-alt: $font-outfit;

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900

// .outfit-<uniquifier> {
//   font-family: "Outfit", sans-serif;
//   font-optical-sizing: auto;
//   font-weight: <weight>;
//   font-style: normal;
// }

/********************************************************/
/* TGS - Colour settings 					 			*/
/********************************************************/

$orange: #f36f21;
$orange-light: #f6955c;

$border-grey: #f3f3f3;
$light-grey: #ededed;
$dark: #373d45;



$green-dark: #728d2a;
$green-light: #f3ffd2;
$dark-grey: #58585a;
$bg-grey: #ededed;

$grey-50: #fafafa !default;
$grey-100: #f5f5f5 !default;
$grey-200: #eeeeee !default;
$grey-300: #e0e0e0 !default;
$grey-400: #bdbdbd !default;
$grey-500: #9e9e9e !default;
$grey-600: #757575 !default;
$grey-700: #616161 !default; // Primary
$grey-800: #424242 !default;
$grey-900: #212121 !default;
$gray-900: #454444;

$grey: #454444;

$dark: #373d45;
$black: #000;
$white: #fff;

$headings-color: $orange;
$body-color: $black;

$h1-font-size: $font-size-base * 2.6;
$h2-font-size: $font-size-base * 1.9;
$h3-font-size: $font-size-base * 1.6;
$h4-font-size: $font-size-base * 1.3;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;

$primary: $orange;
$primary-light: $orange !default;
$primary-dark: $dark !default;

$link-color: $black !important;
$link-hover-color: $orange !important;

//$link-hover-color:  darken($link-color, 15%) !important;

/********************************************************/
/* TGS - Navbar overrides:				              	 			*/
/********************************************************/

/* $navbar-dark-color: rgba($white, 0.8);
$navbar-dark-hover-color: rgba($white, 1);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.5);
$navbar-dark-toggler-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  "#",
  "%23"
);
$navbar-dark-toggler-border-color: rgba($white, 0.5);
 */

$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);

$link-color: $primary;
$link-hover-color: shift-color($link-color, $link-shade-percentage);

$navbar-font: $font-family-heading;
$navbar-link-color: $black;
$navbar-link-weight: 800;
$navbar-link-size: 15px;
$navbar-hover-color: $primary;
$navbar-active-color: $primary;

$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$navbar-dark-hover-color: $primary !important;
$navbar-dark-active-color: $primary !important;

$dropdown-background: $white;
$dropdown-link-weight: 600;
$dropdown-link-size: 15px;
$dropdown-link-color: $dark-grey;
$dropdown-hover-color: $primary;

$dropdown-link-hover-color: $primary !important;
$dropdown-link-active-color: $primary;
$dropdown-dark-link-hover-bg: $dropdown-link-hover-bg;
$dropdown-dark-link-active-color: $dropdown-link-active-color;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg;

$progress-bar-bg: $primary;

$component-active-bg: $primary;

$pagination-hover-color: $white;
$pagination-hover-bg: $primary;
$pagination-hover-border-color: $primary;
$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;

$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

$input-focus-border-color: $primary;
$form-check-input-focus-border: $input-focus-border-color;
$form-switch-focus-color: $input-focus-border-color;
$form-select-focus-border-color: $input-focus-border-color;

$form-check-input-checked-bg-color: $component-active-bg;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-indeterminate-color: $component-active-color;
$form-check-input-indeterminate-bg-color: $component-active-bg;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;

$form-switch-focus-color: $input-focus-border-color;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");

$btn-link-color: $link-color;
$btn-link-hover-color: $link-hover-color;

$form-range-thumb-bg: $component-active-bg;
$form-range-thumb-active-bg: tint-color($component-active-bg, 70%);

// Accordion
$accordion-button-active-bg: tint-color($component-active-bg, 90%);
$accordion-button-active-color: shade-color($primary, 10%);
$accordion-icon-color: $accordion-color;
$accordion-icon-active-color: $accordion-button-active-color;
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
